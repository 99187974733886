import { EMPTY } from "utils/yogaUtils";

const feMessages: Record<string, Record<string, string>> = {
  en: {
    accept: "Accept",
    acceptSelected: "Accept selected",
    "consents.error": "This consent is mandatory to continue",
    "consents.personalDataConsent": "",
    "consents.commercialOfferConsent": "",
    "consents.thirdPartyDataConsent": "",
    "consents.profilingConsent": "",
    related: "related",
    agencyDocuments: "Received documents",
    answersSaved: "Answers saved correctly",
    answersSent: "Answers sent previously",
    back: "Back",
    callToActionForm: "Call to action form",
    campaignDetail: "Campaign Detail",
    campaignForYou: "Tailored For You",
    cancel: "Cancel",
    choosePassword: "Choose your password",
    chooseDocument: "Choose document",
    communicationDate: "Communication date",
    communicationDetail: "Communication detail",
    communicationList: "Communication list",
    communications: "Communications",
    confirm: "Confirm",
    confirmAnswers: "Are you sure you want to save the answers?",
    contactPanel: "Contact panel",
    contentPreview: "Content preview",
    contentType: "Content type",
    contractBranch: "Branch",
    contractDetail: "Detail",
    contractFrequency: "Frequency",
    contractStatus: "Contract status",
    codeRequest:
      "Enter your email address and receive a code to reset your password",
    coverageEndInstant: "Coverage expiration date",
    customerReference: "Customer reference",
    deleteDocumentConfirm: "Permanently delete the selected document?",
    documents: "Documents",
    documentList: "Document List",
    documentsArea: "Documents area",
    documentContract: "Policy",
    documentCreationInstant: "Created",
    documentDescription: "Description",
    documentEntityType: "Related",
    documentSaved: "Document saved",
    documentSize: "Size",
    documentTitle: "Document title",
    dragNDrop: "Drag and drop",
    emailSent:
      "Request sent succesfully, check your mailbox for verification code!",
    expiration: "Expiration",
    expirationDate: "Expire on",
    isExpired: "Expired on",
    firstLogin: "Change your temporary password",
    formLogin: "Please login to compile the form",
    giveConsents: "Privacy policy",
    insertUser: "Insert username or email",
    lastUpdate: "Last update",
    managePolicy: "Manage policy",
    manageProfile: "Manage profile",
    maxSize: "Max size",
    myDocuments: "My documents",
    no: "No",
    notificationNotSent: "Not sent",
    notificationSent: "Notified",
    overview: "Overview",
    paymentStatus: "Payment status",
    policyNumber: "Policy number",
    receivedDocuments: "Received documents",
    reference: "Client reference",
    resetPassword: "Reset password",
    saveAnswers: "Send answers",
    sendForm: "Send",
    saveDocument: "Save",
    sentDocuments: "Sent documents",
    show: "Show",
    showDocument: "Show document",
    sidebarNoCommunications: "No comunications found",
    startDate: "Start date",
    start: "Start",
    title: "Title",
    totalPremium: "Total premium",
    uploadedFiles: "Selected documents",
    uploadDocument: "Upload document",
    "upload.MOTOR": "Please upload MOTOR documents",
    "upload.LIFE": "Please upload LIFE documents",
    "upload.NO_MOTOR": "Please upload NO_MOTOR documents",
    username: "Username",
    userOrEmail: "Enter username or email",
    yes: "Yes",
    yourCampaigns: "Your Campaigns",
    yourDocuments: "Your documents",
    yourPolicies: "Your Policies",
  },
  it: {
    accept: "Accetta",
    acceptSelected: "Accetta selezionati",
    "consents.error": "Questo consenso è obbligatorio per proseguire",
    "consents.personalDataConsent":
      "Acconsento al trattamento dei dati personali comuni e sensibili per le finalità indicate nell’<a class='underline' target='_blank' rel='noopener noreferrer' href='/documents/INFORMATIVA_WEBeCOOKIES_v1.0_67d.it.pdf'>informativa</a>",
    "consents.commercialOfferConsent":
      "Acconsento al trattamento dei miei dati personali di natura comune per finalità di informazione e promozione commerciale di prodotti e/o servizi, a mezzo posta o telefono e/o mediante comunicazioni elettroniche quali e-mail, fax, messaggi del tipo Sms o MMS ovvero con sistemi automatizzati",
    "consents.thirdPartyDataConsent":
      "Acconsento al trattamento dei miei dati personali di natura comune per finalità di comunicazione dei dati a soggetti terzi, operanti nel settore assicurativo, e nei settori complementari a quello assicurativo, ai fini di informazione e promozione commerciale di prodotti e/o servizi, anche mediante tecniche di comunicazione a distanza, da parte degli stessi",
    "consents.profilingConsent":
      "Acconsento al trattamento dei miei dati personali di natura comune per finalità di profilazione volta ad analizzare i bisogni e le esigenze assicurative del cliente per l’individuazione, anche attraverso elaborazioni elettroniche, dei possibili prodotti e/o servizi in linea con le preferenze e gli interessi della clientela",
    related: "associata",
    agencyDocuments: "Documenti ricevuti",
    answersSaved: "Risposte salvate correttamente",
    answersSent: "Risposte inviate precedentemente",
    back: "Indietro",
    callToActionForm: "Compila il questionario",
    campaignDetail: "Dettaglio Campagna",
    campaignForYou: "Pensate Per Te",
    cancel: "Annulla",
    chooseDocument: "Scegli documento",
    choosePassword: "Scegli la tua password",
    communicationDate: "Data comunicazione",
    communicationDetail: "Dettaglio comunicazione",
    communicationList: "Elenco comunicazioni",
    communications: "Comunicazioni",
    confirm: "Conferma",
    confirmAnswers: "Sei sicuro di voler salvare le risposte?",
    contactPanel: "Pannello contatti",
    contentPreview: "Anteprima contenuto",
    contentType: "Tipo di contenuto",
    contractBranch: "Ramo",
    contractDetail: "Dettagli",
    contractFrequency: "Rateazione",
    contractStatus: "Stato contratto",
    coverageEndInstant: "Data scadenza copertura",
    customerReference: "Gestore",
    deleteDocumentConfirm:
      "Eliminare definitivamente il documento selezionato?",
    documents: "Documenti",
    documentList: "Elenco documenti",
    documentsArea: "Area documenti",
    documentContract: "Polizza",
    documentCreationInstant: "Creato il",
    documentDescription: "Descrizione",
    documentEntityType: "Tipo",
    documentTitle: "Nome documento",
    documentSaved: "Documento salvato",
    documentSize: "Dimensione",
    dragNDrop: "Trascina qui un documento",
    emailSent:
      "Richiesta inviata con successo, controlla la tua mail e recupera il codice di verifica!",
    codeRequest:
      "Inserisci il tuo indirizzo email e riceverai un codice per reimpostare la password",
    expiration: "Scadenza",
    expirationDate: "Scade il",
    firstLogin: "Cambia la tua password temporanea",
    formLogin: "Collegati per rispondere alle domande",
    giveConsents: "Consensi privacy",
    insertUser: "Inserire username o email",
    isExpired: "Scaduta il",
    lastUpdate: "Ultimo aggiornamento",
    managePolicy: "Gestisci la polizza",
    manageProfile: "Gestisci profilo",
    maxSize: "Dimensione massima consentita",
    myDocuments: "I miei documenti",
    newPassword: "Nuova password",
    no: "No",
    notificationNotSent: "Non inviata",
    notificationSent: "Notificata",
    overview: "Sintesi",
    passwordChanged: "La password è stata modificata correttamente",
    paymentStatus: "Stato pagamento",
    policyNumber: "Numero polizza",
    receivedDocuments: "Documenti ricevuti",
    reference: "Gestore cliente",
    resetPassword: "Reimposta password",
    saveAnswers: "Salva le risposte",
    sendForm: "Invia",
    saveDocument: "Carica",
    sentDocuments: "Documenti inviati",
    show: "Visualizza",
    showDocument: "Visualizza documento",
    sidebarNoCommunications: "Nessuna comunicazione",
    submit: "Procedi",
    startDate: "Data decorrenza",
    start: "Decorrenza",
    title: "Titolo",
    totalPremium: "Premio totale",
    uploadedFiles: "Documenti selezionati",
    uploadDocument: "Carica documento",
    uploadDocs: "Carica qui i documenti richiesti dall’agente",
    username: "Username",
    userOrEmail: "Inserisci username o email",
    yes: "Si",
    yourCampaigns: "Le tue campagne",
    yourDocuments: "I tuoi documenti",
    yourPolicies: "Le tue polizze",
    verificationCode: "Codice di verifica",
  },
};
// add empty messages as defaults
Object.values(feMessages).forEach((r) => (r[EMPTY] = ""));

export { feMessages };
