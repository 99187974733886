import { useState } from "react";

type Language = string;
const DEFAULT_LANGUAGE = "it";

const getLanguage = (): Language => {
  return DEFAULT_LANGUAGE;
};

/**
 * useNavigatorLanguage hook
 * Returns the language of the navigator
 * @return {Language}
 */
export function useNavigatorLanguage(): Language {
  const [language] = useState<Language>(getLanguage);
  return language;
}
