import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "providers/AuthProvider";
import { Party } from "models/Party";
import { profileService } from "services/profileService";

export function useProfile() {
  const [party, setParty] = useState<Party>();
  const [error, setError] = useState<Error | undefined>(undefined);
  const mountedRef = useRef(true);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && user.attributes) {
      const username = user.attributes.email;
      const fetchData = async () => {
        console.log(`recording login for user ${username}`);
        await profileService.recordLogin(username);
        const result = await profileService.get(username);
        const data = result.data;
        if (!mountedRef.current) return;
        setParty(data);
      };

      fetchData().catch((e) => {
        if (!mountedRef.current) return;
        setError(e);
      });
    }
  }, [user]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    party,
    error,
  };
}
