import React, { ReactNode, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { EMPTY } from "utils/yogaUtils";
import { XIcon } from "@heroicons/react/outline";
import { isMobile } from "react-device-detect";

interface ModalPros {
  header?: string;
  children: ReactNode;
  isModalOpen?: boolean;
  setIsModalOpen?: any;
  classNameWidth?: string;
}

export function Modal({
  children,
  header,
  isModalOpen = false,
  setIsModalOpen,
  classNameWidth = "w-1/3",
}: ModalPros) {
  const modalRef = useRef(null);
  const backDropRef = useRef(null);
  const desktopModalClassName = `modal bg-white lg:${classNameWidth} lg:h-auto`;

  function toggleModal() {
    // @ts-ignore
    modalRef.current.classList.toggle("hidden");
    // @ts-ignore
    modalRef.current.classList.toggle("flex");
    // @ts-ignore
    backDropRef.current.classList.toggle("hidden");
    // @ts-ignore
    backDropRef.current.classList.toggle("flex");
  }

  useEffect(() => {
    if (isModalOpen) {
      toggleModal();
      setIsModalOpen(isModalOpen);
    }
  }, [isModalOpen]);

  return (
    <>
      {isMobile && isModalOpen == true && (
        <>
          <div
            className=" overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center  hidden "
            ref={modalRef}
          >
            <div className="modal bg-white w-full h-full">
              <div className="modal-header p-4 md:p-8 inline-flex w-full flex-row-reverse items-center">
                <div className="modal-close">
                  <button
                    className="bg-transparent border-0 text-gray-300 text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <XIcon className="w-10 h-10 text-67d-black" />
                  </button>
                </div>
                <h3 className="modal-title bg-white text-67d-black font-bold text-xl hidden md:block md:flex-1">
                  <FormattedMessage id={header ?? EMPTY} />
                </h3>
              </div>
              <div className="modal-body w-full bg-white mt-2 p-8 ">
                <h3 className="modal-title bg-white text-67d-black font-bold text-xl mb-8 md:hidden">
                  <FormattedMessage id={header ?? EMPTY} />
                </h3>
                {children}
              </div>
            </div>
          </div>
          <div
            className="hidden opacity-25 fixed inset-0 z-40 bg-black"
            ref={backDropRef}
          />
        </>
      )}
      {!isMobile && isModalOpen == true && (
        <>
          <div
            className=" overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center  hidden "
            ref={modalRef}
          >
            <div className={desktopModalClassName}>
              <div className="modal-header p-4 md:p-8 inline-flex w-full flex-row-reverse items-center">
                <div className="modal-close">
                  <button
                    className="bg-transparent border-0 text-gray-300 text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <XIcon className="w-10 h-10 text-67d-black" />
                  </button>
                </div>
                <h3 className="modal-title bg-white text-67d-black font-bold text-xl hidden md:block md:flex-1">
                  <FormattedMessage id={header ?? EMPTY} />
                </h3>
              </div>
              <div className="modal-body w-full bg-white mt-2 p-8 ">
                <h3 className="modal-title bg-white text-67d-black font-bold text-xl mb-8 md:hidden">
                  <FormattedMessage id={header ?? EMPTY} />
                </h3>
                {children}
              </div>
            </div>
          </div>
          <div
            className="hidden opacity-25 fixed inset-0 z-40 bg-black"
            ref={backDropRef}
          />
        </>
      )}
    </>
  );
}
