import React from "react";
import { useRef } from "react";
import HomeHeader from "components/homepage/HomeHeader";
import HomeMenu from "components/homepage/HomeMenu";
import JumboHeader from "components/homepage/JumboHeader";
import HomeNewsSection from "components/homepage/HomeNewsSection";
import HomeStaffSection from "components/homepage/HomeStaffSection";
import HomeFooter from "components/homepage/HomeFooter";
import "./67D.css";
import staffImage from "assets/images/staff.jpg";
import MainSection from "../../components/homepage/MainSection";

export default function JobOpportunities() {
  const menu = useRef(null);
  const staff = useRef(null);

  return (
    <>
      <HomeHeader menu={menu} />
      <HomeMenu menu={menu} />
      <JumboHeader image={staffImage}>
        <div className="tito">
          Lavora&nbsp;
          <br className="hidden lg:block" />
          con noi.
        </div>
        <div className="desc">
          Siamo a Torino dal 1992: conosciamo le persone e per loro siamo un
          punto di riferimento.
        </div>
      </JumboHeader>

      <MainSection>
        <p className="font-bold text-2xl lg:text-5xl mb-4 lg:mb-8 text-center">
          Il tuo futuro,
          <br /> in buone mani.
          <hr />
        </p>
        <div className="w-full lg:w-1/2 mx-auto">
          <div className="text-base lg:text-3xl font-medium">
            Siamo in costante ricerca di consulenti commerciali per sviluppare
            il nostro team. E forse stiamo cercando proprio te...
          </div>

          <div className="text-base lg:text-xl font-light mt-4">
            Per presentare la tua candidatura invia un&apos;email a{" "}
            <a href="mailto:cv@67d.it?subject=Candidatura&amp;body=Ricorda%20di%20allegare%20CV%20e%20Lettera%20%20di%20presentazione.%20Grazie.">
              questo indirizzo
            </a>
            , allegando oltre al tuo curriculum vitae una lettera di
            presentazione.
            <div className="mt-4 lg:mt-8">
              <a
                role="button"
                href="mailto:cv@67d.it?subject=Candidatura&amp;body=Ricorda%20di%20allegare%20CV%20e%20Lettera%20%20di%20presentazione.%20Grazie."
                className="inline-flex py-2 px-6 bg-67d-red text-white w-full text-center items-center justify-center font-normal"
              >
                Candidati
              </a>
            </div>
          </div>
        </div>
      </MainSection>
      <HomeNewsSection staff={staff} />
      <HomeStaffSection staff={staff} />
      <HomeFooter />
    </>
  );
}
