import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { MutableRefObject } from "react";
import MenuItems from "./MenuItems";

type HomeMenuProps = {
  menu: MutableRefObject<HTMLDivElement | null>;
};

export default function HomeMenu({ menu }: HomeMenuProps): JSX.Element {
  const toggleMenu = () => {
    if (menu.current?.classList) {
      menu.current.classList.add("hidden");
      menu.current.classList.remove("fixed");
    }
  };
  return (
    <div
      className="hidden h-full bg-white text-67d-black z-40 top-0 right-0 w-10/12 md:w-1/2"
      id="menu"
      ref={menu}
    >
      <div className="flex flex-col h-full justify-center items-center">
        <div className="text-right self-end p-4">
          <a onClick={toggleMenu} role="button">
            <XIcon className="w-10 h-10 text-67d-black" />
          </a>
        </div>
        <div className="flex-auto flex items-center justify-center">
          <ul className="list-none text-center font-bold text-xl">
            <MenuItems close={toggleMenu} />
          </ul>
        </div>
      </div>
    </div>
  );
}
