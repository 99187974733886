import React from "react";
import { ReactNode } from "react";

export default function MainSection({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <section id="main" className="p-8 md:p-16">
      {children}
    </section>
  );
}
