import React from "react";
import { ReactNode } from "react";

interface JumboInterface {
  image: string;
  children: ReactNode;
}

export default function JumboHeader({
  image,
  children,
}: JumboInterface): JSX.Element {
  return (
    <header className="public" style={{ display: "block" }}>
      <div className="overlay" />
      <img
        style={{ height: "100%", objectFit: "cover" }}
        src={image}
        alt="payload background"
      />
      <div className="flex flex-col justify-center h-full w-full items-start claim pl-4 ">
        {children}
      </div>
    </header>
  );
}
