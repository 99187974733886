import { KeyValue, OrderedEntity, YuidEntity } from "models/YogaModels";
import { Content } from "models/Content";

export const normalizeYuidList = <T extends YuidEntity>(list: T[]) =>
  Object.fromEntries(list.map((yuidObject) => [yuidObject.yuid, yuidObject]));

export const sortByOrder = <T extends OrderedEntity>(array: T[]): T[] =>
  array.sort((a: T, b: T) => a.order - b.order);

export const getParamByYuid = (params: KeyValue<YuidEntity>, yuid: string) =>
  Object.values(params).find((it) => it.yuid == yuid);

export const EMPTY = " ";

const thumbnailCodeToUrl: KeyValue<string> = {
  motor_1: "thumbnail_car.png",
  no_motor_1: "thumbnail_building.png",
  life_1: "thumbnail_umbrella.png",
  default: "thumbnail_default.png",
};

export const getThumbnailUrls = (content?: Content): string[] => [
  `/campaigns/${thumbnailCodeToUrl[content?.thumbnail ?? "default"]}`,
  `/campaigns/${thumbnailCodeToUrl.default}`,
];
