import React, { CSSProperties, PropsWithChildren } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const HomeCarousel = ({ children }: PropsWithChildren<any>) => {
  const indicatorStyles: CSSProperties = {
    background: "#ccc",
    borderBottom: "1rem transparent",
    borderTop: "1rem transparent",
    marginTop: "2rem",
    width: "2rem",
    height: "0.25rem",
    display: "inline-block",
    margin: "0 8px",
  };

  return (
    <Carousel
      className="mx-auto h-full mt-4"
      showThumbs={false}
      autoPlay={true}
      showArrows={true}
      infiniteLoop={true}
      statusFormatter={() => ""}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: "#9b1b1b" }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      {children &&
        React.Children.map(children, (child) => {
          return <>{child}</>;
        })}
    </Carousel>
  );
};

export default HomeCarousel;
