import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createContext, useState } from "react";
import { useNavigatorLanguage } from "hooks/useNavigatorLanguage";
import { feMessages } from "assets/i18n/messages";
import { IntlProvider } from "react-intl";
import { KeyValue } from "models/YogaModels";
import Loader from "./components/common/Loader";
import { Windmill } from "@windmill/react-ui";
import { UserProvider } from "providers/AuthProvider";
import PublicHome from "pages/public/PublicHome";
import OurServices from "pages/public/OurServices";
import { Layout } from "components/Layout";
import axios from "axios";
import { Auth } from "aws-amplify";
import JobOpportunities from "./pages/public/JobOpportunities";
import useTranslations from "hooks/useTranslations";

if (process.env.NODE_ENV !== "test") {
  axios.interceptors.request.use(
    async (request) => {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const jwtToken = authenticatedUser?.signInUserSession?.idToken?.jwtToken;
      if (jwtToken) {
        request.headers["x-jwt-assertion"] = "Bearer " + jwtToken; // atm we`re using Authorization for Basic auth
      }
      return request;
    },
    (error) => Promise.reject(error)
  );
}

export const customWindmillTheme = {
  backdrop: {
    base: "fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-start sm:justify-center",
  },
  button: {
    base: "inline-flex items-center justify-center leading-5 transition-colors duration-150 font-medium focus:outline-none",
    primary: {
      active:
        "active:bg-67d-orange-600 hover:bg-67d-orange-700 focus:ring focus:ring-purple-300 cursor-pointer",
    },
    outline: {
      active:
        "active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:ring focus:ring-gray-300 cursor-pointer",
    },
  },
  card: {
    base: "ring-0 border-0 shadow-none overflow-hidden",
  },
  cardBody: {
    base: "p-0",
  },
};

interface ContextType {
  lang: string;
  setLang: (s: string) => void;
  loading: boolean;
  setLoading: (b: boolean) => void;
  redirectUrl: string;
  setRedirectUrl: (s: string) => void;
}

const noop = () => {};
export const Context = createContext({
  lang: "",
  setLang: noop,
  loading: false,
  setLoading: noop,
  redirectUrl: "",
  setRedirectUrl: noop,
} as ContextType);

function App() {
  const langNav = useNavigatorLanguage();
  const [lang, setLang] = useState(langNav);
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("/reserved");
  const { beMessages } = useTranslations();
  let loadingCount = 0;
  const messages: KeyValue<string> = {
    ...feMessages[lang],
    ...beMessages[lang],
  };

  axios.interceptors.request.use((req) => {
    if (req.url !== "/api/v2/translations") {
      loadingCount++;
      setLoading(true);
    }
    return req;
  });
  axios.interceptors.response.use((resp) => {
    if (resp.request.url !== "/api/v2/translations") {
      loadingCount--;
    }
    if (loadingCount == 0) setLoading(false);
    return resp;
  });

  const i18n = {
    messages: messages,
    key: lang,
    locale: lang,
    defaultLocale: "it",
    onError: noop,
  };

  return (
    <UserProvider>
      <Context.Provider
        value={{
          lang,
          setLang,
          loading,
          setLoading,
          redirectUrl,
          setRedirectUrl,
        }}
      >
        <IntlProvider {...i18n}>
          <Windmill theme={customWindmillTheme}>
            <BrowserRouter basename="/">
              <Switch>
                <Route exact path="/" component={PublicHome} />
                <Route exact path="/servizi" component={OurServices} />
                <Route exact path="/lavora" component={JobOpportunities} />
                <Route path="/reserved" component={Layout} />
                <Route component={() => <div>Pagina non trovata</div>} />
              </Switch>
            </BrowserRouter>
          </Windmill>
        </IntlProvider>
        <Loader loading={loading} />
      </Context.Provider>
    </UserProvider>
  );
}

export default App;
