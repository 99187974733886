import React from "react";
import { LogoutIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import mainLogo from "assets/images/67D_logo.svg";
import { FormattedMessage } from "react-intl";
import { UserInfo } from "components/common/UserInfo";
import useLoginUtils from "hooks/useLoginUtils";
import { isMobile } from "react-device-detect";
import { useState } from "react";

const Header = () => {
  const { signOut } = useLoginUtils();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const logOut = () => {
    signOut().then(() => {
      history.push("/");
    });
  };

  return (
    <header className="z-40 p-4 xl:p-0 bg-white shadow-bottom flex">
      <div className="flex flex-row justify-between xl:justify-start xl:flex-shrink-0 w-full xl:w-1/4 lg:pl-8">
        <div className="flex">
          <Link to="/reserved">
            <img
              className={isMobile ? "max-h-10" : ""}
              alt="67D"
              src={mainLogo}
            />
          </Link>
        </div>
        {isMobile && (
          <div className="flex my-auto">
            <MenuIcon
              className="w-10 h-10 text-67d-black"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            />
          </div>
        )}
        {!isMobile && (
          <div className="flex self-center flex-col pl-8 text-lg font-bold text-67d-black">
            <span>Agenzia Torino</span>
            <span>Via Maria Vittoria</span>
          </div>
        )}
      </div>

      {isMobile && isMenuOpen && (
        <div className="w-4/5 md:w-1/2 fixed z-50 top-0 right-0 h-full bg-white flex flex-col justify-center">
          <div className="absolute top-0 right-0 flex justify-end p-4">
            <XIcon
              className="w-10 h-10 text-67d-black"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            />
          </div>
          <div className="flex flex-col gap-16 justify-between">
            <div className="flex items-center">
              <UserInfo />
            </div>
            <div className="flex flex-col gap-4 items-center mx-auto">
              <div className="flex items-center mx-auto text-xl font-bold">
                <Link to="/reserved" onClick={() => setIsMenuOpen(false)}>
                  Homepage
                </Link>
              </div>
              <div className="flex items-center mx-auto text-xl font-bold">
                <Link
                  to="/reserved/documents"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <FormattedMessage id="documentsArea" />
                </Link>
              </div>
            </div>
            <div
              data-qa="log-out"
              className="bg-67d-gray-5 px-6 py-2 cursor-pointer mx-auto"
              onClick={logOut}
            >
              <LogoutIcon className="h-6 float-left mr-3" />
              Logout{" "}
            </div>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="flex flex-row justify-between w-full lg:pr-8 text-67d-black">
          <div className="flex items-center gap-9 text-xl pl-8">
            <Link to="/reserved">Homepage</Link>
            <Link to="/reserved/documents">
              <FormattedMessage id="documentsArea" />
            </Link>
          </div>
          <div className="flex items-center">
            <UserInfo />
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
