import { Consent } from "models/Consent";

const CONSENT_MOCK_1: Consent = {
  code: "personalDataConsent",
  mandatory: true,
  agreed: true,
};

const CONSENT_MOCK_2: Consent = {
  code: "commercialOfferConsent",
  mandatory: false,
  agreed: true,
};

const CONSENT_MOCK_3: Consent = {
  code: "thirdPartyDataConsent",
  mandatory: false,
  agreed: true,
};

const CONSENT_MOCK_4: Consent = {
  code: "profilingConsent",
  mandatory: false,
  agreed: true,
};

export const CONSENTS_MOCK: Consent[] = [
  CONSENT_MOCK_1,
  CONSENT_MOCK_2,
  CONSENT_MOCK_3,
  CONSENT_MOCK_4,
];
