import { useContext, useState } from "react";
import { UserContext } from "providers/AuthProvider";
import { Auth } from "aws-amplify";

export default function useLoginUtils() {
  const [isPasswordChanged, setPasswordChanged] = useState(false);
  const {
    setIsAuthorized,
    setIsFirstLogin,
    setIsAuthenticating,
    setError,
    firstLoginUser,
    setFirstLoginUser,
    setUser,
    setHasLoggedOut,
    setHasConsentsGiven,
  } = useContext(UserContext);

  const firstPasswordChange = (newPassword: string) => {
    Auth.completeNewPassword(firstLoginUser, newPassword)
      .then(() => {
        setIsAuthorized(true);
        setError(undefined);
      })
      .catch((e) => {
        setError(e);
      });
  };

  const setNewPasswordRequired = (isRequired: boolean) => {
    setIsFirstLogin(isRequired);
  };

  const signOut = (): Promise<any> =>
    Auth.signOut().then(() => {
      setHasLoggedOut(true);
      setIsAuthorized(false);
      setError(undefined);
      setUser(undefined);
      setHasConsentsGiven(false);
    });

  const signIn = (usernameOrEmail: string, password: string) => {
    setIsAuthenticating(true);
    Auth.signIn(usernameOrEmail, password)
      .then((cognitoUser) => {
        if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          setFirstLoginUser(cognitoUser);
          setIsFirstLogin(true);
        } else {
          setIsAuthorized(true);
        }
        setError(undefined);
      })
      .catch((err) => {
        if (
          err.code == "UserNotFoundException" ||
          err.code == "NotAuthorizedException"
        ) {
          err.message = "Password o nome utente errati";
        }
        setIsAuthorized(false);
        setError(err);
      })
      .finally(() => setIsAuthenticating(false));
  };

  const forgotPassword = async (usernameOrEmail: string) => {
    // Send confirmation code to user's email
    await Auth.forgotPassword(usernameOrEmail)
      .then((data) => {
        console.log(data);
        setError(undefined);
      })
      .catch((err) => console.log(err));
  };

  const forgotPasswordSubmit = (
    usernameOrEmail: string,
    code: string,
    newPassword: string
  ) => {
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(usernameOrEmail, code, newPassword)
      .then(() => {
        setPasswordChanged(true);
        setError(undefined);
      })
      .catch((err) => {
        if (err.code == "ExpiredCodeException") {
          err.message =
            "Codice fornito non valido, richiedi nuovamente un codice";
        } else if (err.code == "LimitExceededException") {
          err.message = "Limite di tentativi superato, riprova più tardi";
        }
        setError(err);
      });
  };

  return {
    firstPasswordChange,
    setNewPasswordRequired,
    setIsFirstLogin,
    setPasswordChanged,
    isPasswordChanged,
    signIn,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
  };
}
