import { useEffect, useState } from "react";
import { KeyValue } from "models/YogaModels";
import axios from "axios";
import { Translation } from "models/Translation";

export default function useTranslations() {
  const api: string = "/api/v2/translations";
  const [beMessages, setBeMessages] = useState<KeyValue<KeyValue<string>>>({});
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${api}`);
      const translations: Translation[] = result.data;
      let messages: KeyValue<KeyValue<string>> = { it: {}, en: {} };
      translations.forEach((t) => {
        messages[t.language][t.key] = t.value;
      });
      setBeMessages(messages);
    };
    fetchData().catch((e) => setError(e));
  }, []);

  return { beMessages, error };
}
