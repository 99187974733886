import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useContext, useEffect, useState } from "react";
import { Modal } from "components/common/Modal";
import { UserContext } from "providers/AuthProvider";
import { useProfile } from "hooks/useProfile";
import { CONSENTS_MOCK } from "models/__mocks__/Consents.mock";
import { Consent } from "models/Consent";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { profileService } from "services/profileService";
import { Profile } from "models/Profile";
import * as Yup from "yup";

const consents = CONSENTS_MOCK;

const mandatoryConsents: string[] = (consents || [])
  .filter((consents) => {
    return consents.mandatory;
  })
  .map((obj) => {
    return obj.code;
  });

export function checkIfAllMandatoryAreGiven(profile: Profile | undefined) {
  const profileConsents = (profile?.consents || [])
    .filter((consents) => {
      return consents.agreed;
    })
    .map((obj) => {
      return obj.code;
    });

  let difference = mandatoryConsents.filter(
    (x) => !profileConsents.includes(x)
  );

  return difference.length === 0;
}

export default function ConsentsModal() {
  const { setHasConsentsGiven } = useContext(UserContext);
  const { party } = useProfile();
  const intl = useIntl();

  const values = consents.reduce((a, v) => ({ ...a, [v.code]: v.agreed }), {});

  const initialTouched = consents.reduce(
    (a, v) => ({ ...a, [v.code]: true }),
    {}
  );

  const validationSchema = Yup.object().shape(
    mandatoryConsents.reduce(
      (a, consentsCode) => ({
        ...a,
        [consentsCode]: Yup.bool().oneOf(
          [true],
          intl.formatMessage({
            id: "consents.error",
          })
        ),
      }),
      {}
    )
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (checkIfAllMandatoryAreGiven(party?.profile)) {
      setIsModalOpen(false);
      setHasConsentsGiven(true);
    } else if (party?.profile) {
      setIsModalOpen(true);
    }
  }, [party?.profile]);

  const onSubmit = async (values: any) => {
    const consents = Object.entries(values).map((consents) => {
      return {
        code: consents[0],
        agreed: consents[1] as boolean,
      };
    });
    if (party?.profile?.userName) {
      await profileService.patchConsents(party?.profile.userName, consents);
      setIsModalOpen(false);
      setHasConsentsGiven(true);
    }
  };

  function getRadioElement(consents: Consent) {
    const message = intl.formatMessage({ id: `consents.${consents.code}` });

    return (
      <div key={consents.code} className="mb-4">
        <div className="flex flex-grow justify-between">
          <div
            className="text-justify"
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <label
            htmlFor={consents.code}
            className="flex items-start cursor-pointer justify-start"
            data-qa={`${consents.code}-label`}
          >
            <div className="relative ml-5 my-auto">
              <Field
                type="checkbox"
                name={consents.code}
                id={consents.code}
                className="sr-only block"
                data-qa={`${consents.code}-input`}
              />
              <div className="w-10 rail-privacy h-4 bg-cruiser-grey rounded-full shadow-inner checked:bg-67d-red" />
              <div className="dot absolute w-6 h-6 bg-deep-lilac rounded-full shadow -left-1 -top-1 transition" />
            </div>
          </label>
        </div>
        <ErrorMessage
          name={consents.code}
          component="div"
          className="text-67d-red text-sm"
        />
      </div>
    );
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        header="giveConsents"
        classNameWidth="w-1/2"
      >
        <div>
          <Formik
            onSubmit={onSubmit}
            initialValues={values}
            validationSchema={validationSchema}
            className="mt-6 text-67d-black"
            validateOnMount
            initialTouched={initialTouched}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <fieldset disabled={false}>
                  <div className="flex flex-col space-y-6 text-67d-black">
                    <div className="mb-2">
                      {consents.map((consents) => {
                        return getRadioElement(consents);
                      })}
                    </div>
                    <button
                      type="submit"
                      className="bg-67d-red hover:bg-67d-red p-2 text-white"
                    >
                      <FormattedMessage id="acceptSelected" />
                    </button>
                  </div>
                </fieldset>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}
