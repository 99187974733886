import axios from "axios";
import { Consent } from "models/Consent";

const api_party: string = "/api/v2/parties";
const api_profile: string = "/api/v2/profiles";

export const profileService = {
  get: (username: string) => axios.get(`${api_party}/username/${username}`),
  patchConsents: (username: string, consents: Consent[]) =>
    axios.patch(`${api_profile}/username/${username}/consents`, consents),
  recordLogin: (username: string) =>
    axios.put(`${api_profile}/${username}/lastAccessInstant`)
};
