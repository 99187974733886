import React from "react";
import { useRef } from "react";
import HomeHeader from "components/homepage/HomeHeader";
import HomeMenu from "components/homepage/HomeMenu";
import JumboHeader from "components/homepage/JumboHeader";
import HomeNewsSection from "components/homepage/HomeNewsSection";
import HomeStaffSection from "components/homepage/HomeStaffSection";
import HomeFooter from "components/homepage/HomeFooter";
import agenzia from "assets/images/agenzia.jpg";
import "./67D.css";
import MainSection from "../../components/homepage/MainSection";

export default function Services() {
  const menu = useRef(null);
  const staff = useRef(null);

  return (
    <>
      <HomeHeader menu={menu} />
      <HomeMenu menu={menu} />
      <JumboHeader image={agenzia}>
        <div className="tito">
          I nostri&nbsp;
          <br className="hidden lg:block" />
          Servizi.
        </div>
        <div className="desc">
          Dal 1992 siamo accanto ai nostri clienti con l’obiettivo di costruire
          relazioni che durino nel tempo
        </div>
      </JumboHeader>
      <MainSection>
        <p className="font-bold text-2xl lg:text-5xl mb-4 lg:mb-8 text-center">
          Il tuo futuro,
          <br /> in buone mani.
          <hr />
        </p>
        <div className="w-full lg:w-2/3 mx-auto">
          <div className="inline-flex gap-4 lg:gap-16 flex-wrap lg:flex-nowrap w-full justify-around">
            <div className="text-base lg:text-xl font-light lg:w-2/3">
              Con il mandato di Generali Italia l’agenzia 67D offre una vasta
              gamma di prodotti assicurativi, di investimento e bancari per ogni
              tipo di esigenza. Le nostre aree di eccellenza assicurativa sono
              rivolte alle coperture dei rischi aziendali e di pianificazione
              finanziaria, fiscale con le coperture dedicate al trattamento di
              fine mandato degli amministratori e dei relativi uomini chiave
              dell’azienda.
            </div>

            <div className="text-center lg:text-left text-base lg:text-3xl font-medium lg:w-1/3">
              4.000 clienti
              <br /> 8.000 contratti
              <br />
              nel ramo vita,
              <br />
              danni e auto.
            </div>
          </div>
        </div>
      </MainSection>
      <HomeNewsSection staff={staff} />
      <HomeStaffSection staff={staff} />
      <HomeFooter />
    </>
  );
}
