import React from "react";
import "./Sidebar.css";
import { ReactNode } from "react";

export const Sidebar = ({ children }: { children: ReactNode }) => {
  return (
    <aside className="z-30 flex-shrink-0 w-1/4 overflow-y-auto bg-67d-gray-3 block">
      <div>{children}</div>
    </aside>
  );
};
