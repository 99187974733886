import {
  ChatIcon,
  HomeIcon,
  SpeakerphoneIcon,
  UsersIcon,
} from "@heroicons/react/solid";
import * as React from "react";
import { lazy } from "react";
import { isMobile } from "react-device-detect";

export const routes = [
  {
    path: "/reserved",
    component: lazy(() => import("pages/Home")),
    sidebar: lazy(
      () => import("components/communication/CommunicationSidebarList")
    ),
  },
  {
    path: "/reserved/campaigns/:campaignId",
    component: lazy(() => import("pages/CampaignDetail")),
    sidebar: !isMobile
      ? lazy(() => import("components/campaign/CampaignsSidebarList"))
      : null,
  },
  {
    path: "/reserved/communications/:communicationId",
    component: lazy(() => import("pages/CommunicationDetail")),
    sidebar: !isMobile
      ? lazy(() => import("components/communication/CommunicationSidebarList"))
      : null,
  },
  {
    path: "/reserved/documents",
    component: lazy(() => import("pages/Documents")),
    sidebar: !isMobile
      ? lazy(() => import("components/communication/CommunicationSidebarList"))
      : null,
  },
  {
    path: "/reserved/contracts/:contractId",
    component: lazy(() => import("pages/ContractDetail")),
    sidebar: !isMobile
      ? lazy(() => import("components/contract/ContractSidebarList"))
      : null,
  },
];

export interface MenuItem {
  path: string;
  key: string;
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  routes?: MenuItem[];
  exact?: boolean;
}

export const menuItems: MenuItem[] = [
  {
    path: "/",
    icon: HomeIcon,
    key: "home",
  },
  {
    path: "/",
    icon: UsersIcon,
    key: "customers",
  },
  {
    path: "/",
    icon: ChatIcon,
    key: "communications",
  },
  {
    path: "/",
    icon: SpeakerphoneIcon,
    key: "campaigns",
  },
];
