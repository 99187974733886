import React from "react";
import logoFooter from "assets/images/67D-foot.png";

export default function HomeFooter(): JSX.Element {
  return (
    <footer>
      <div className="flex flex-row flex-wrap lg:flex-nowrap items-center h-full mb-4 lg:gap-16 justify-between">
        <div className="flex flex-grow logo flex-shrink-0">
          <img src={logoFooter} alt="footer logo" className="dimension" />
        </div>
        <div className="lg:order-4 my-auto">
          <a href="https://www.facebook.com/AgGeneraliTorinoViaMariaVittoria">
            <i
              className="bi bi-facebook"
              style={{ color: "#fff", fontSize: "3rem" }}
            />
          </a>
        </div>
        <div className="block md:hidden lg:block w-full">
          <div className="w-auto lg:flex flex-row flex-grow">
            <div className="flex-grow w-full lg:w-auto iva">
              <div className="flex flex-col gap-2 my-auto lg:w-auto two_third">
                <p>
                  <b className="font-bold">
                    Moscatelli Federico e Pennisi Gioacchino SNC
                  </b>
                  <br />
                  Partita IVA: 11369960015
                </p>
                <p>
                  <b className="font-bold">
                    Numero e data iscrizione al registro intermediari
                  </b>
                  <br />
                  A000530836 &#40;sezione A: agenti&#41; - settembre 2015
                </p>
                <p>
                  <b className="font-bold">
                    Imprese per le quali è svolta l&#39;attività
                  </b>
                  <br />
                  <ul>
                    <li>
                      D.A.S. DIFESA AUTOMOBILISTICA SINISTRA S.P.A. DI
                      ASSICURAZIONE
                    </li>
                    <li>GENERALI ITALIA S.P.A.</li>
                    <li>EUROP ASSISTANCE ITALIA S.P.A.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="flex-grow w-full lg:w-auto contatti">
              <div className="flex flex-col gap-2 my-auto lg:w-auto five_sixts">
                <p>
                  <b className="font-bold">
                    Responsabili attività di intermediazione Assicurativa
                  </b>
                </p>
                <div className="flex gap-2">
                  <p className="flex-grow">
                    <b className="font-bold">Pennisi Gioacchino</b>
                    <br />
                    Numero iscrizione al registro intermediari
                    <br />
                    A000003846
                  </p>
                  <p className="flex-grow">
                    <b className="font-bold">Moscatelli Federico</b>
                    <br />
                    Numero iscrizione al registro intermediari
                    <br />
                    A000004104
                  </p>
                </div>
                <b className="font-bold">
                  Ogni agente è soggetto a{" "}
                  <a
                    href="https://servizi.ivass.it/RuirPubblica/"
                    className="underline"
                  >
                    vigilanza Ivass
                  </a>
                </b>
              </div>
            </div>
            <div className="flex-grow w-full lg:w-auto recapiti">
              <div className="flex flex-col gap-2 my-auto lg:w-auto">
                <p>
                  <b className="font-bold">Sede Legale e Operativa</b>
                  <br />
                  Via Maria Vittoria, 6 - 10123 Torino
                </p>
                <p>
                  <b className="font-bold">Sede Operativa</b>
                  <br />
                  Corso Francia 115 - 10138 Collegno
                </p>
                <p>
                  <b className="font-bold">Recapiti Telefonici</b>
                  <br />
                  Tel 011 8123503 - Fax 011 883711
                </p>
                <p>
                  <b className="font-bold">Pec o Reclami</b>
                  <br />
                  moscatelliepennisisnc@arubapec.it
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:hidden w-full mt-6">
          <div className="flex">
            <div className="flex-grow w-full lg:w-auto iva">
              <div className="flex flex-col gap-2 my-auto w-auto">
                <p>
                  <b className="font-bold">
                    Moscatelli Federico e Pennisi Gioacchino SNC
                  </b>
                  <br />
                  Partita IVA: 11369960015
                </p>
                <p>
                  <b className="font-bold">
                    Numero e data iscrizione al registro intermediari
                  </b>
                  <br />
                  A000530836 &#40;sezione A: agenti&#41; - settembre 2015
                </p>
                <p>
                  <b className="font-bold">
                    Imprese per le quali è svolta l&#39;attività
                  </b>
                  <br />
                  <ul>
                    <li>
                      D.A.S. DIFESA AUTOMOBILISTICA SINISTRA S.P.A. DI
                      ASSICURAZIONE
                    </li>
                    <li>GENERALI ITALIA S.P.A.</li>
                    <li>EUROP ASSISTANCE ITALIA S.P.A.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="lg:w-auto recapiti">
              <div className="flex flex-col gap-2 my-auto lg:w-auto">
                <p>
                  <b className="font-bold">Sede Legale e Operativa</b>
                  <br />
                  via Maria Vittoria, 6 - 10123 Torino
                </p>
                <p>
                  <b className="font-bold">Sede Operativa</b>
                  <br />
                  Corso Francia 115 - 10138 Collegno
                </p>
                <p>
                  <b className="font-bold">Recapiti Telefonici</b>
                  <br />
                  Tel 011 8123503 - Fax 011 883711
                </p>
                <p>
                  <b className="font-bold">Pec o Reclami</b>
                  <br />
                  moscatelliepennisisnc@arubapec.it
                </p>
              </div>
            </div>
          </div>
          <div className="flex-grow w-full lg:w-auto contatti">
            <div className="flex flex-col gap-2 my-auto lg:w-auto five_sixts">
              <p>
                <b className="font-bold">
                  Responsabili attività di intermediazione Assicurativa
                </b>
              </p>
              <div className="flex gap-2">
                <p className="flex-grow">
                  <b className="font-bold">Pennisi Gioacchino</b>
                  <br />
                  Numero iscrizione al registro intermediari
                  <br />
                  A000003846
                </p>
                <p className="flex-grow">
                  <b className="font-bold">Moscatelli Federico</b>
                  <br />
                  Numero iscrizione al registro intermediari
                  <br />
                  A000004104
                </p>
              </div>
              <b className="font-bold">
                Ogni agente è soggetto a{" "}
                <a
                  href="https://servizi.ivass.it/RuirPubblica/"
                  className="underline"
                >
                  vigilanza Ivass
                </a>
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="last_line">
        <p>
          <b className="font-bold">
            Quest&#39;area non sostituisce quella presente sul sito della
            compagnia ex Regolamento Ivassn n. 41/2028 che consente altre e
            ulteriori funzionalità.
          </b>
        </p>
      </div>
    </footer>
  );
}
