import React from "react";
import { MutableRefObject } from "react";

type HomeStaffSectionProps = {
  staff: MutableRefObject<HTMLDivElement | null>;
};

export default function HomeStaffSection({
  staff,
}: HomeStaffSectionProps): JSX.Element {
  const separator =
    "my-2 lg:my-4 border-b-0 border-l-0 border-r-0 border-gray-300";
  return (
    <div id="staff" className="hidden staff p-8 md:p-16" ref={staff}>
      <div className="flex flex-auto flex-wrap justify-start gap-4">
        <div className="flex-grow w-full lg:w-auto">
          <div className="testo">
            <b>Agenti</b>
            <hr className={separator} />
          </div>
          <div className="testo">Pennisi Gioacchino</div>
          <div className="testo">Moscatelli Federico</div>
        </div>
        <div className="flex-grow w-full lg:w-auto">
          <div className="testo">
            <b>Organizzazione amministrativa</b>
            <hr className={separator} />
          </div>
          <div className="testo">
            Bergamin Antonella (contabilit&agrave; ed emissioni)
          </div>
          <div className="testo">Galliana Daniela (sinistri ed emissioni)</div>
          <div className="testo">Maserati Giorgia (emissioni)</div>
          <div className="testo">Tonini Marco (emissioni e marketing)</div>
          <div className="testo">
            Barsi Christian (contabilit&agrave; ed emissioni)
          </div>
          <div className="testo">
            Antonio Moscatelli (sinistri ed emissioni)
          </div>
        </div>

        <div className="flex-grow w-full lg:w-auto">
          <div className="testo">
            <b>Organizzazione commerciale</b>
            <hr className={separator} />
          </div>
          <div className="testo mt-8">
            <b>Subagenti</b>
            <hr className={separator} />
          </div>
          <div className="testo">
            Fioretta Alberto (punto remoto di Collegno)
          </div>
          <div className="testo">Palazzo Sabrina</div>
          <div className="testo">Pucciatti Bruna</div>
          <div className="testo">Cassone Pietro</div>
          <div className="testo">Skorus Edyta</div>
          <div className="testo">Cordaro Andrea</div>

          <div className="testo mt-8">
            <b>Organizzazione produttiva dipendente di Generali Italia Spa</b>
            <hr className={separator} />
          </div>
          <div className="testo">
            Abbattista Rosa (tutor e trainer di 3° livello)
          </div>
          <div className="testo">Valvano Stefano (2° livello)</div>
          <div className="testo">Masseroni Claudia Raffaella</div>
          <div className="testo">Lo Conti Roberto</div>
          <div className="testo">Quattrocolo Arturo</div>
          <div className="testo">Ramini Fabio</div>
          <div className="testo">Raineri Elena</div>
        </div>
      </div>
    </div>
  );
}
