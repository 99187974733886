import React from "react";
import { Link, useLocation } from "react-router-dom";

type MenuItemsProps = {
  close?: () => any;
};
export default function MenuItems({ close }: MenuItemsProps): JSX.Element {
  const current = useLocation();

  const onLink = (path: string) => {
    if (close) {
      console.log(current);
      if (current.pathname !== path) {
        close();
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      <li className="p-4">
        <Link to="/" onClick={() => onLink("/")}>
          Home
        </Link>
      </li>
      <li className="p-4">
        <a data-scroll href="#news" onClick={close}>
          Chi siamo
        </a>
      </li>
      <li className="p-4">
        <Link to="/servizi" onClick={() => onLink("/servizi")}>
          Servizi
        </Link>
      </li>
      <li className="p-4">
        <Link to="/lavora" onClick={() => onLink("/lavora")}>
          Lavora con noi
        </Link>
      </li>
    </>
  );
}
