import React from "react";
import { MenuIcon } from "@heroicons/react/outline";
import logo from "assets/images/67D_logo.svg";
import { MutableRefObject } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";

type HomeHeaderProps = {
  menu: MutableRefObject<HTMLDivElement | null>;
};

export default function HomeHeader({ menu }: HomeHeaderProps): JSX.Element {
  const toggleMenu = () => {
    if (menu.current?.classList) {
      menu.current.classList.remove("hidden");
      menu.current.classList.add("fixed");
    }
  };

  return (
    <section id="navigation" className="bg-white lg:py-6 py-4 lg:px-0 px-4">
      <div className="w-full">
        <div className="grid grid-cols-12">
          <div className="col-span-2 my-auto lg:right-shadow lg:text-right">
            <Link to="/">
              <img className="max-h-10 lg:float-right" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="col-span-8 lg:block lg:col-span-3 my-auto brand text-left">
            <div className="hidden lg:block">
              <Link to="/">
                Agenzia Torino
                <br />
                Maria Vittoria
              </Link>
            </div>
          </div>

          <div className="col-span-2 lg:hidden my-auto">
            <a onClick={toggleMenu} role="button" className="float-right">
              <MenuIcon className="w-10 h-10 text-67d-black" />
            </a>
          </div>
          <div className="hidden lg:block lg:col-span-7 my-auto text-right">
            <div className="flex justify-end mr-4">
              <ul
                className="flex flex-row justify-end flex-grow list-none mx-3 text-center text-lg"
                style={{ opacity: "0.8" }}
              >
                <MenuItems />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
