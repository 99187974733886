import React from "react";
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import Amplify, { Auth } from "aws-amplify";
import { User } from "models/User";

//TODO move configuration to environment variables
const realAuth = {
  // REQUIRED - Amazon Cognito Region
  region: "eu-central-1",
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: "eu-central-1_V2kLaQLWl",
  userPoolWebClientId: "53im48geuv8nps805nv9pga7a0",
};
const mockAuth = {
  region: "eu-fake-1",
  userPoolId: "eu-fake-1_424242",
  userPoolWebClientId: "4242424242",
};
Amplify.configure({
  Auth: process.env.NODE_ENV === "test" ? mockAuth : realAuth,
});

interface UserCtxType {
  user: User | undefined;
  setUser: any;
  error: any;
  setError: any;
  isAuthenticating: boolean;
  setIsAuthenticating: any;
  isAuthorized: boolean;
  setIsAuthorized: any;
  isFirstLogin: boolean;
  setIsFirstLogin: any;
  firstLoginUser: any;
  setFirstLoginUser: any;
  hasLoggedOut: boolean;
  wasUserAuthChecked: boolean;
  setHasLoggedOut: any;
  setWasUserAuthChecked: any;
  hasConsentsGiven: boolean;
  setHasConsentsGiven: any;
}

function useUser(config?: any): UserCtxType {
  const [user, setUser] = useState(undefined);
  const [firstLoginUser, setFirstLoginUser] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  // Initially false; set to true when user manually logs out
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  // Initially false; set to true after retrieving user from Cognito (which may or may not be authenticated)
  const [wasUserAuthChecked, setWasUserAuthChecked] = useState(false);

  const [hasConsentsGiven, setHasConsentsGiven] = useState(false);

  const auth = useMemo(() => {
    Auth.configure(config);
    return Auth;
  }, []);

  useEffect(() => {
    auth
      .currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => setUser(undefined))
      .finally(() => setWasUserAuthChecked(true));
  }, [isAuthorized]);

  return {
    user,
    setUser,
    error,
    setError,
    isAuthenticating,
    setIsAuthenticating,
    isAuthorized,
    setIsAuthorized,
    isFirstLogin,
    setIsFirstLogin,
    firstLoginUser,
    setFirstLoginUser,
    hasLoggedOut,
    wasUserAuthChecked,
    setHasLoggedOut,
    setWasUserAuthChecked,
    hasConsentsGiven,
    setHasConsentsGiven,
  };
}

export const UserContext = createContext<UserCtxType>({
  user: undefined,
  setUser: () => {},
  error: undefined,
  setError: () => {},
  isAuthenticating: false,
  setIsAuthenticating: () => {},
  isFirstLogin: false,
  setIsFirstLogin: () => {},
  isAuthorized: false,
  setIsAuthorized: () => {},
  firstLoginUser: undefined,
  setFirstLoginUser: () => {},
  hasLoggedOut: false,
  wasUserAuthChecked: false,
  setHasLoggedOut: () => {},
  setWasUserAuthChecked: () => {},
  hasConsentsGiven: false,
  setHasConsentsGiven: () => {},
});

export function UserProvider({ children }: PropsWithChildren<{}>) {
  const authUser = useUser();
  return (
    <UserContext.Provider value={authUser}>{children}</UserContext.Provider>
  );
}
