import React from "react";
const Loader = ({ loading }: { loading: boolean }) => {
  return (
    <>
      {loading && (
        <div className="flex fixed top-0 left-0 right-0 bottom-0 justify-center items-center opacity-50 bg-white z-50">
          <div
            className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"
            data-qa="spinner-loader"
          ></div>
        </div>
      )}
    </>
  );
};

export default Loader;
