import React from "react";
import peopleImg from "assets/images/soci-small-taglio.png";
import { MutableRefObject } from "react";

type HomeNewSectionProps = {
  staff: MutableRefObject<HTMLDivElement | null>;
};

export default function HomeNewsSection({
  staff,
}: HomeNewSectionProps): JSX.Element {
  const toggleStaff = () => {
    if (staff.current?.classList) {
      staff.current.classList.toggle("hidden");
    }
    if (!staff.current?.classList?.contains("hidden")) {
      document.getElementById("staff")?.scrollIntoView();
    }
  };

  return (
    <section id="news" className="p-8 md:px-16 md:pt-16 md:pb-0">
      <div className="w-full 2xl:w-2/3 2xl:mx-auto">
        <div className="inline-flex w-full items-end">
          <div className="flex-auto pb-0 md:pb-16">
            <div className="headline">
              Gioacchino Pennisi
              <a
                className="pl-6"
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/gioacchino-pennisi-142a4267/"
              >
                <i className="bi bi-linkedin" style={{ color: "#ccc" }} />
              </a>
            </div>
            <div className="testo">
              Agente Rappresentante Procuratore Generali Italia SPA e Financial
              Planning Agent Banca Generali SPA
            </div>
            <div className="headline">
              Federico Moscatelli
              <a
                className="pl-6"
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/federico-moscatelli-482b373a/"
              >
                <i className="bi bi-linkedin" style={{ color: "#ccc" }} />
              </a>
            </div>
            <div className="testo">
              Agente Rappresentante Procuratore Generali Italia SPA e Financial
              Planning Agent Banca Generali SPA
            </div>

            <button
              type="button"
              id="btn-staff"
              className="border border-gray-800 py-2 px-4 lg:px-6"
              onClick={toggleStaff}
            >
              Staff
            </button>
          </div>

          <div className="hidden lg:block">
            <img
              style={{ width: "65%", marginLeft: "auto", marginRight: "auto" }}
              src={peopleImg}
              alt="picture"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
