import React from "react";
import icon from "assets/images/icon.png";
import iconFamily from "assets/images/ico-family.png";
import iconArt from "assets/images/ico-art.png";
import HomeCarousel from "./HomeCarousel";

export default function HomeMainSection(): JSX.Element {
  const cssContainer =
    "w-full lg:w-2/3 h-full flex flex-row items-center gap-12 mx-auto text-left";
  const cssFirstCol = "hidden md:block flex-shrink-0";
  const cssSecondCol = "";

  return (
    <section id="main" className="p-8 md:p-16 lg:pb-32">
      <div className="w-full text-center">
        <div className="pre">Le nostre specializzazioni</div>
      </div>
      <HomeCarousel>
        <div className={cssContainer} key="uno">
          <div className={cssFirstCol}>
            <img
              src={icon}
              alt="icon"
              style={{ maxHeight: 187, width: "auto" }}
            />
          </div>
          <div className={cssSecondCol}>
            <div className="carotito">
              <a href="#">Financial Advisory</a>
            </div>
            <div className="carodesc">
              Dagli investimenti finanziari alla protezione della tua famiglia e
              della tua impresa, ti aiutiamo a realizzare i tuoi progetti
              curando ogni aspetto.
            </div>
          </div>
        </div>

        <div className={cssContainer} key="due">
          <div className={cssFirstCol}>
            <img
              src={iconFamily}
              alt="family"
              style={{ maxHeight: 187, width: "auto" }}
            />
          </div>
          <div className={cssSecondCol}>
            <div className="carotito">
              <a href="#">Family Protection</a>
            </div>
            <div className="carodesc">
              Pianificazione del passaggio generazionale e della gestione delle
              successioni, valutando i rischi fiscali, legali e imprenditoriali.
            </div>
          </div>
        </div>

        <div className={cssContainer} key="tre">
          <div className={cssFirstCol}>
            <img
              src={iconFamily}
              alt="family"
              style={{ maxHeight: 187, width: "auto" }}
            />
          </div>
          <div className={cssSecondCol}>
            <div className="carotito">
              <a href="#">Real Estate Advisory</a>
            </div>
            <div className="carodesc">
              Con i principali player del settore garantiamo una gestione
              efficiente delle proprietà, anche attraverso operazioni di
              riqualificazione e compravendita.
            </div>
          </div>
        </div>

        <div className={cssContainer} key="quattro">
          <div className={cssFirstCol}>
            <img
              src={icon}
              alt="icon"
              style={{ maxHeight: 187, width: "auto" }}
            />
          </div>
          <div className={cssSecondCol}>
            <div className="carotito">
              <a href="#">Corporate Advisory</a>
            </div>
            <div className="carodesc">
              Ti aiuteremo a compiere le scelte giuste con operazioni di finanza
              straordinaria, creazioni di business plan, partnership
              strategiche, valutazioni d’azienda, riorganizzazioni, espansioni e
              cessioni.
            </div>
          </div>
        </div>

        <div className={cssContainer} key="cinq">
          <div className={cssFirstCol}>
            <img
              src={iconArt}
              alt="art"
              style={{ maxHeight: 187, width: "auto" }}
            />
          </div>
          <div className={cssSecondCol}>
            <div className="carotito">
              <a href="#">Art Advisory</a>
            </div>
            <div className="carodesc">
              Analisi e valutazione del patrimonio artistico, valorizzazione
              tramite mostre e pubblicazioni, custodia, conservazione e
              restauro, acquisto e vendita di opere.
            </div>
          </div>
        </div>
      </HomeCarousel>
    </section>
  );
}
