import { useRef } from "react";
import HomeHeader from "components/homepage/HomeHeader";
import HomeMenu from "components/homepage/HomeMenu";
import JumboHeader from "components/homepage/JumboHeader";
import HomeMainSection from "components/homepage/HomeMainSection";
import HomeNewsSection from "components/homepage/HomeNewsSection";
import HomeStaffSection from "components/homepage/HomeStaffSection";
import HomeFooter from "components/homepage/HomeFooter";
import { Link } from "react-router-dom";
import jumboBackground from "assets/images/pexels-photo-3933099.jpeg";
import "./67D.css";
import React from "react";
import CookieConsent from "react-cookie-consent";

export default function Home() {
  const menu = useRef(null);
  const staff = useRef(null);

  return (
    <>
      <HomeHeader menu={menu} />
      <HomeMenu menu={menu} />
      <JumboHeader image={jumboBackground}>
        <div data-qa="jumbo-title" className="tito">
          Il tuo futuro,
          <br />
          in buone mani.
        </div>
        <div className="desc">
          26 professionisti che si impegnano ogni giorno per proteggere la tua
          vita, le persone che ami e il tuo futuro.
        </div>
        <button
          type="button"
          id="btn-services"
          className="border border-white px-4 lg:px-6 py-2 hover:bg-white hover:text-67d-black duration-300"
        >
          <Link to="/servizi">I nostri servizi</Link>
        </button>
      </JumboHeader>
      <HomeMainSection />
      <HomeNewsSection staff={staff} />
      <HomeStaffSection staff={staff} />
      <HomeFooter />
      <CookieConsent buttonText="Chiudi" location="top" acceptOnScroll>
        Questo sito può trattare i dati personali dell’utente attraverso
        l’utilizzo dei cosiddetti cookies che potrebbero consentire
        l’identificazione dell’utente o del terminale. Per saperne di più è
        possibile{" "}
        <a
          href="/documents/COOKIEPOLICY_WEB_v1.0_67d.it.pdf"
          target="_blank"
          className="underline"
        >
          cliccare qui
        </a>
        . Questo banner soddisfa l’obbligo di resa dell’informativa sintetica. A
        maggior tutela degli utenti, abbiamo implementato un meccanismo per cui,
        chiudendo questo banner, si potrà navigare sul sito senza essere
        identificati.
      </CookieConsent>
    </>
  );
}
