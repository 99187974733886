import { Dropdown } from "@windmill/react-ui";
import React, { useState } from "react";
import { useProfile } from "hooks/useProfile";
import user from "assets/images/icon_user.svg";
import { isMobile } from "react-device-detect";
import useLoginUtils from "hooks/useLoginUtils";
import { useHistory } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import { FormattedMessage } from "react-intl";

export const UserInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const { party } = useProfile();
  const { signOut } = useLoginUtils();
  const history = useHistory();

  const logOut = () => {
    signOut().then(() => {
      history.push("/");
    });
  };

  return (
    <>
      {party && party.profile && !isMobile && (
        <>
          <div
            data-qa="user-info"
            className="relative flex flex-row"
            onClick={toggleDropdown}
          >
            <span className="w-12 h-12 float-right rounded-full flex-shrink-0 cursor-pointer">
              <img alt="user" src={user} />
            </span>
            {!isOpen && (
              <span className="my-auto ml-2 cursor-pointer">
                <ChevronDownIcon className="h-6" />
              </span>
            )}
            {isOpen && (
              <span className="my-auto ml-2 cursor-pointer">
                <ChevronUpIcon className="h-6" />
              </span>
            )}
            <div className="text-xl my-auto mt-10">
              <span className="font-bold">
                <Dropdown
                  align="right"
                  isOpen={isOpen}
                  onClose={() => {}}
                  className="mt-4 p-8 w-max border border-67d-gray-2 z-40"
                >
                  <div
                    data-qa="user-info-data"
                    className="flex flex-col gap-5 font-normal text-67d-black"
                  >
                    <div>
                      <div className="font-bold">
                        {party.name} {party.surnameOrCompanyName}
                      </div>
                      <div className="text-lg">
                        <FormattedMessage id="customerReference" />{" "}
                        {party.customerReference}
                      </div>
                    </div>
                    <div className="border-t border-b border-67d-gray-2 py-5">
                      {party.location && <div>{party.location.label}</div>}
                      <div>{party.profile.email}</div>
                    </div>
                    <div
                      data-qa="log-out"
                      className="bg-67d-gray-5 px-6 py-2 cursor-pointer float-left mr-auto"
                      onClick={logOut}
                    >
                      <LogoutIcon className="h-6 float-left mr-3" />
                      Logout{" "}
                    </div>
                  </div>
                </Dropdown>
              </span>
            </div>
          </div>
        </>
      )}
      {party && party.profile && isMobile && (
        <>
          <div
            data-qa="user-info"
            className="flex flex-col text-center mx-auto gap-3"
          >
            <div className="w-8 h-8 rounded-full mx-auto">
              <img alt="user" src={user} />
            </div>
            <div className="text-xl mx-auto">
              <span className="font-bold">
                {party.name} {party.surnameOrCompanyName}
                <div
                  data-qa="user-info-data"
                  className="flex flex-col text-xs text-67d-black py-3"
                >
                  <div className="mb-2">{party.profile.email}</div>
                  {party.location && <div>{party.location.label}</div>}
                </div>
              </span>
              <div className="text-xs">
                <FormattedMessage id="customerReference" />
              </div>
              <div className="text-xs">{party.customerReference}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
