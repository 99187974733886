import React from "react";
import { PropsWithChildren, Suspense, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Sidebar } from "components/Sidebar";
import ThemedSuspense from "components/common/ThemedSuspense";
import { routes } from "routes";
import Header from "components/Header";
import { UserContext } from "providers/AuthProvider";
import { Context } from "App";
import { useHistory } from "react-router";
import { isMobile } from "react-device-detect";
import { useProfile } from "hooks/useProfile";
import { checkIfAllMandatoryAreGiven } from "components/common/ConsentsModal";
import HomeFooter from "./homepage/HomeFooter";

const Main = ({ children }: PropsWithChildren<{}>) => (
  <main className="h-full w-full overflow-y-auto">
    <div className="grid h-full">{children}</div>
  </main>
);

export function Layout() {
  const { user, hasLoggedOut, wasUserAuthChecked } = useContext(UserContext);
  const { setRedirectUrl } = useContext(Context);
  const history = useHistory();
  const { party } = useProfile();

  function goToLogin() {
    const url = window.location.pathname;
    if (url.startsWith("/reserved")) {
      setRedirectUrl(url);
    } else {
      setRedirectUrl("/reserved");
    }
    history.push(hasLoggedOut ? "/" : "/#login");
  }

  useEffect(() => {
    if (wasUserAuthChecked && !user) {
      goToLogin();
    }
  }, [user, wasUserAuthChecked, hasLoggedOut]);

  useEffect(() => {
    if (party && party.profile && !checkIfAllMandatoryAreGiven(party.profile)) {
      goToLogin();
    }
  }, [party?.profile]);

  return (
    <div className="flex flex-col min-h-screen w-full">
      <Header />
      <div className="flex-auto flex">
        {!isMobile && (
          <Sidebar>
            <Suspense fallback={<ThemedSuspense />}>
              <Switch>
                {routes.map((route: any, i: number) => {
                  const Sidebar = route.sidebar;
                  return Sidebar ? (
                    <Route
                      key={i}
                      exact={true}
                      path={route.path}
                      render={(props) => <Sidebar {...props} />}
                    />
                  ) : null;
                })}
              </Switch>
            </Suspense>
          </Sidebar>
        )}

        <Main>
          <Suspense fallback={<ThemedSuspense />}>
            {isMobile && (
              <div className="bg-67d-gray-3">
                {routes.map((route: any, i: number) => {
                  const Sidebar = route.sidebar;
                  return Sidebar ? (
                    <Route
                      key={i}
                      exact={true}
                      path={route.path}
                      render={(props) => <Sidebar {...props} />}
                    />
                  ) : null;
                })}
              </div>
            )}
            <Switch>
              {routes.map((route: any, i: number) => {
                const Component = route.component;
                return Component ? (
                  <Route
                    key={i}
                    exact={true}
                    path={route.path}
                    render={(props) => <Component {...props} />}
                  />
                ) : null;
              })}
            </Switch>
          </Suspense>
        </Main>
      </div>
      <HomeFooter />
    </div>
  );
}
